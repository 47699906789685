import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsAndConditions from "./Pages/TermsAndConditions.js";

import {Home} from "./Pages/Home.js";
import ErrorPage from "./Pages/ErrorPage.js";
import TrackPromotion from "./Pages/TrackPromotion.js";

const App = () => {
  return (
    <Router>  
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/tracking" element={<TrackPromotion/>} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;

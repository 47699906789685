
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import QuizBoxLogo from "../assets/quizboxlogo.png";
import classes from "./Home.module.css";
import { fetchMsisdnFunction, fetchTokenFunction, sendPromotionDataFunction } from "../Services/apiFunction";

export const Home = () => {
  const [loading, setLoading] = useState(true);
  const [msisdn, setMsisdn] = useState(null);
  const [token, setToken] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Hardcoded values
  const shortCode = "27093";
  const keyword = "QB";

  useEffect(() => {
    const executeFlow = async () => {
      try {
        setLoading(true);

        // Generate or get `extRef`
        const extRef = searchParams.get("ext_ref") || uuidv4();

        // Fetch token
        const tokenData = await fetchTokenFunction();
        if (!tokenData) throw new Error("Token fetch failed");

        setToken(tokenData.access_token);

        // Fetch msisdn after token is set
        const msisdnData = await fetchMsisdnFunction(tokenData.access_token);
        if (!msisdnData) throw new Error("MSISDN fetch failed");

        setMsisdn(msisdnData);

        // Send promotion data with the updated msisdn
        await sendPromotionDataFunction(msisdnData, extRef);

        setTimeout(() => {
          onSubscribe();
        }, 2000); 


      } catch (error) {
        console.error("Error in executeFlow:", error);
        navigate("*");
      } finally {
        setLoading(false);
      }
    };

    executeFlow();
  }, [navigate, searchParams]);

  // Function to trigger SMS subscription
  const onSubscribe = () => {
    try {
      const smsUri = `sms:${shortCode}?body=${encodeURIComponent(keyword)}`;
      console.log("Redirecting to SMS:", smsUri);

      // Redirect user to SMS app
      window.location.href = smsUri;
    } catch (error) {
      console.error("Subscription failed:", error);
    }
  };

  return (
    <div className={classes.container}>
      <img src={QuizBoxLogo} alt="quiz-box" className={classes.logoImage} />
      <h1 className={classes.title}>QuizBox</h1>
      {loading && <ClipLoader color="#00FF00" size={50} />}
      <p className={classes.costText}>Cost: 10 KSH / day</p>
      <a href={`/terms?svc=quizbox`} className={classes.termsLink}>
        Terms & Conditions
      </a>
    </div>
  );
};





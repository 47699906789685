
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./TrackPromotion.css";

// const TrackPromotion = () => {
//   const [skipCount, setSkipCount] = useState(1);
//   const [data, setData] = useState({ skipped: 0, sent: 0, pending: 0 });

//   useEffect(() => {
//     fetchData(skipCount);
//   }, [skipCount]);

//   const fetchData = async (skip) => {
//     try {
//       const response = await axios.get(`https://notificationafricom.digitizeconsultant.com/tracking?skipCount=${skip}`);
//       const responseData = response.data;

//       // Initialize data object
//       let updatedData = { skipped: 0, sent: 0, pending: 0 };

//       // Map API response to frontend state
//       responseData.forEach((item) => {
//         if (item.status.toLowerCase() === "skip") {
//           updatedData.skipped = Number(item.count);
//         } else if (item.status.toLowerCase() === "sent") {
//           updatedData.sent = Number(item.count);
//         } else if (item.status.toLowerCase() === "pending") {
//           updatedData.pending = Number(item.count);
//         }
//       });

//       setData(updatedData);
//     } catch (error) {
//       console.error("Error fetching data", error);
//     }
//   };

//   return (
//     <div className="container">
//       <h2 className="header">Africom Promotion Tracker</h2>
//       <select
//         className="select-box"
//         value={skipCount}
//         onChange={(e) => setSkipCount(Number(e.target.value))}
//       >
//         {[...Array(20)].map((_, i) => (
//           <option key={i + 1} value={i + 1}>{`Skip ${i + 1}`}</option>
//         ))}
//       </select>
//       <button className="button" onClick={() => fetchData(skipCount)}>
//         Refresh Data
//       </button>
//       <div className="card">
//         <p>Skipped: {data.skipped}</p>
//         <p>Sent: {data.sent}</p>
//         <p>Pending: {data.pending}</p>
//       </div>
//     </div>
//   );
// };

// export default TrackPromotion;


import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TrackPromotion.css";

const TrackPromotion = () => {
  const [skipCount, setSkipCount] = useState(null); // Initially null
  const [data, setData] = useState({ skipped: 0, sent: 0, pending: 0 });

  // Fetch skipCount from database on page load
  useEffect(() => {
    fetchSkipCount();
  }, []);

  // Fetch data when skipCount is set
  useEffect(() => {
    if (skipCount !== null) {
      fetchData(skipCount);
    }
  }, [skipCount]);

  const fetchSkipCount = async () => {
    try {
      const response = await axios.get("https://notificationafricom.digitizeconsultant.com/get-skip-count");
      setSkipCount(response.data); // Set skipCount from API
    } catch (error) {
      console.error("Error fetching skip count", error);
      setSkipCount(1); // Default to 1 if API fails
    }
  };

  const fetchData = async (skip) => {
    try {
      const response = await axios.get(`https://notificationafricom.digitizeconsultant.com/tracking?skipCount=${skip}`);
      const responseData = response.data;

      let updatedData = { skipped: 0, sent: 0, pending: 0 };

      responseData.forEach((item) => {
        if (item.status.toLowerCase() === "skip") {
          updatedData.skipped = Number(item.count);
        } else if (item.status.toLowerCase() === "sent") {
          updatedData.sent = Number(item.count);
        } else if (item.status.toLowerCase() === "pending") {
          updatedData.pending = Number(item.count);
        }
      });

      setData(updatedData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleSkipChange = (e) => {
    const newSkipCount = Number(e.target.value);
    setSkipCount(newSkipCount);
  };

  return (
    <div className="container">
      <h2 className="header">Africom Promotion Tracker</h2>

      {skipCount !== null ? (
        <>
          <select
            className="select-box"
            value={skipCount}
            onChange={handleSkipChange}
          >
            {[...Array(20)].map((_, i) => (
              <option key={i + 1} value={i + 1}>{`Skip ${i + 1}`}</option>
            ))}
          </select>
          <button className="button" onClick={() => fetchData(skipCount)}>
            Refresh Data
          </button>
          <div className="card">
            <p>Skipped: {data.skipped}</p>
            <p>Sent: {data.sent}</p>
            <p>Pending: {data.pending}</p>
          </div>
        </>
      ) : (
        <p>Loading skip count...</p> // Show loading message until data is fetched
      )}
    </div>
  );
};

export default TrackPromotion;

import axios from 'axios'

export const fetchTokenFunction = async () => {
    const url = "https://he-api.subscrption.gamepoa.com/appToken/getHashedToken";

    try{
        const response = await axios.get(url);
        return response.data;
    }
    catch(err){
        console.log(err)
        throw err;
    }
};




export const fetchMsisdnFunction = async (token) => {
    const url = "https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn";

    try {
        const response = await axios.get(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "X-App": "he-partner",
                "X-Source-System": "he-partner",
                "X-messageid": "1234",
            }
        });

        // Extract the Msisdn value safely
        const msisdn = response?.data?.ServiceResponse?.ResponseBody?.Response?.Msisdn || null;

    return msisdn;
 
      
    } catch (err) {
        console.error(err);
       throw err;
     
      
      
    }
};


// not in use
export const fetchUserAgentFunction = async () => {
    const url = "https://notificationafricom.digitizeconsultant.com/africom/v1/header";


    try{
        const response = await axios.post(url)
        return response.data;
    }
    catch(err){
        console.log(err)
        throw err
    }

};



export const performSubscriptionFunction = async (msisdn, user_agent, host,token,extRef) => {
    const url = "https://api.mfilter.gamepoa.com/app/v1/subscribe";
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        msisdn,
        offerCode: "1736928578466",
        redirectUrl: "https://apiquizdemo.gamewins.co.za/",
        clickId: extRef,
        sourceIp: host,
        userAgent: user_agent,
    };

    try{
        const response = await axios.post(url,data,{headers})
        return response.data
    }
    catch(err){
        console.log(err)
        throw err;
    }

};



export const sendPromotionDataFunction = async (msisdn_user,extRef) => {
    const promotionRequestBody = {
      msisdn : msisdn_user,
      ext_ref: extRef,
      serviceName: "QuizBox_1",
      reference: "mfid", 
    };
    try{
        const response = await axios.post("https://notificationafricom.digitizeconsultant.com/promotion/",promotionRequestBody)
      // const response = await axios.post("http://localhost:4670/promotion/",promotionRequestBody)
        return response.data;

    }
    catch(error){
      console.log(error)
      throw error;
    }
  };